import $ from 'jquery'

import { yes, onChangeLayout, inViewport, watch, assign, animateAccordion } from './utility'
import { getDefaultScrollTarget } from './scroll'
import { EventEmitter } from './module'

/**
 * 排他的に開くアコーディオン。開いた地点が画面外に移動したらスクロール。ほとんどのケースで {@link accordion} を使用すると良い。
 *
 * Accordion that opens exclusively. They scroll the browser if the accordion leave out of viewport.
 * In most cases, you should use {@link accordion} function.
 *
 * 以下のイベントを発火する
 * - select アコーディオンが選択された時。index が渡される
 * - deselect アコーディオンの選択が解除された時
 * - beforeOpen アコーディオンが開かれる直前
 * - opened アコーディオンが開かれた時
 * - beforeClose アコーディオンが閉じられる直前
 * - closed アコーディオンが閉じられた時
 *
 * @example
 * // wrapper element of accordion
 * const $nav = $('#js-nav')
 *
 * const vm = new AccordionVM($nav, {
 *   // link or button element that toggles an accordion
 *   anchor: '.js-accordion_anchor',
 *   // content of accordion
 *   content: '.js-accordion_content'
 * })
 *
 * vm.on('select', index => vm.select(index))
 * vm.on('deselect', () => vm.select(null))
 *
 * @example
 * // pug の例。アコーディオンを開閉するボタンと、コンテンツのラッパーに js から参照するためのクラス名を付与する。
 * // Here is the example of pug. You need to add class attributes that are referred from js -
 * // buttons to open/close accordion and wrappers of accordion contents.
 *
 * #js-nav
 *   ul.foo
 *     li.foo_item
 *       a.foo_anchor.js-accordion_anchor(aria-selected="false") Toggle accordion
 *       ul.bar.js-accordion_content(aria-expanded="false")
 *         li.bar_item ...
 *
 * @example
 * // scss の例。コンテンツに対して height を対象とした transition を設定する。また、各属性の値が変わった時のスタイルも指定する。
 * // Here is the example of scss. You need to define transition property for height. In addition,
 * // it is needed to define the style when each attribute value is changed.
 *
 * .foo_anchor[aria-selected="true"] {
 *   color: $color-sub;
 * }
 *
 * .bar {
 *   transition: height $transition-accordion;
 *
 *   &[aria-expanded="false"] {
 *     display: none;
 *   }
 * }
 */
export class AccordionVM extends EventEmitter {
  /**
   * @param {jQuery} $wrapper - アコーディオンのラッパー要素 (Wrapper element of accordion)
   * @param {Object} options
   * @param {string} options.content
   * - 各アコーディオンのコンテンツ (content of accordion)
   * @param {string} options.anchor
   * - 各アコーディオンを開け閉めするリンク・ボタン (link or button for opening/closing accordion)
   * @param {string} [options.scrollTarget]
   * - アコーディオン開閉時にスクロールする要素 (element that will be scrolled when the accordion is changed)
   * @param {number|function} [options.scrollOffset=0]
   * - scrollTarget のスクロール時に基準からどれだけずらすか。関数を渡すとスクロールの度に評価する (offset of scroll destination point)
   * @param {string} [options.selectedAttr='aria-selected']
   * - options.anchor を選択しているか否かを表す属性名 (attribute name whether the options.anchor is selected or not)
   * @param {string} [options.expandedAttr='aria-expanded']
   * - options.content が開いているか否かを表す属性名 (attribute name whether the options.content is opened or not)
   * @param {function(): boolean} [options.filterClick]
   * - boolean を返す関数を定義する。true を返すと click 時にアコーディオンが動作する。
   */
  constructor ($wrapper, options) {
    super()

    /** @private */
    this.data = {
      openedIndex: null,
      disabled: false
    }

    this.bind(this.data, $wrapper, options)
    this.isAnimating = false
  }

  /** @private */
  bind (data, $wrapper, options) {
    const {
      content,
      anchor,
      disableScroll = false,
      scrollTarget,
      scrollOffset = 0,
      selectedAttr = 'aria-selected',
      expandedAttr = 'aria-expanded',
      filterClick = yes
    } = options

    // selectedAttr の値を見て初期状態を判別し、実際の値に反映させる
    // また、DOM に選択状態が書かれていない時は選択していないものとして属性を書き込む
    this._syncInitialState(
      $wrapper.find(anchor),
      $wrapper.find(content),
      selectedAttr,
      expandedAttr
    )

    this.$scrollTarget = scrollTarget
      ? $wrapper.find(scrollTarget)
      : getDefaultScrollTarget()
    this.scrollOffset = scrollOffset

    // アコーディオンのトリガをクリックした時
    $wrapper.on('click', anchor, event => {
      if (data.disabled || !filterClick()) return

      if (event.button !== 0) return // 左クリック以外は処理しない
      event.preventDefault()

      const $anchor = $(event.currentTarget)
      const isSelected = $anchor.attr(selectedAttr) === 'true'

      if (isSelected) {
        // 選択されていたら解除
        this.trigger('deselect')
      } else {
        // 選択したアコーディオンを開く
        const $anchorList = $wrapper.find(anchor)
        const index = $anchorList.index($anchor)
        this.trigger('select', index)
      }
    })

    watch(data, 'disabled', disabled => {
      const $contents = $wrapper.find(content)

      if (disabled) {
        $contents.attr(expandedAttr, 'true')
      } else {
        $contents.attr(expandedAttr, 'false')

        const index = this.data.openedIndex
        if (index != null && !isNaN(index)) {
          $contents
            .eq(this.data.openedIndex)
            .attr(expandedAttr, 'true')
        }
      }
    })

    watch(data, 'openedIndex', (newIndex, oldIndex) => {
      const $anchors = $wrapper.find(anchor)
      const $contents = $wrapper.find(content)

      // 表示されているものを非表示にする
      if (oldIndex != null && !isNaN(oldIndex)) {
        // アコーディオンを閉じることでスクロール位置が不自然な位置にならないように、
        // スクロール位置の調整
        if (!disableScroll) {
          const $targetAnchor = newIndex != null
            ? $anchors.eq(newIndex)
            : $anchors.eq(oldIndex)
          const $prevContent = $contents.eq(oldIndex)
          const $nextContent = newIndex != null ? $contents.eq(newIndex) : $()
          this._adjustScroll($targetAnchor, $nextContent, $prevContent)
        }
        this._hideContent(oldIndex, $anchors, $contents, selectedAttr, expandedAttr)
      }

      // インデックスが指定されている時は
      // 対応するドロップダウンを表示する
      if (newIndex != null && !isNaN(newIndex)) {
        this._showContent(newIndex, oldIndex, $anchors, $contents, selectedAttr, expandedAttr, disableScroll)
      }
    })
  }

  /**
   * index 番目のアコーディオンを開く。index = null の時は選択を解除する
   * disabled の時は何もしない
   * @param {?number} index - アコーディオンのインデックス (index of accordion)
   */
  select (index) {
    if (this.data.disabled) return
    this.data.openedIndex = index
  }

  /**
   * アコーディオンを無効化し、すべてのコンテンツを開く
   */
  disable () {
    this.data.disabled = true
  }

  /**
   * アコーディオンを有効化する
   */
  enable () {
    this.data.disabled = false
  }

  /**
   * アコーディオンのアンカーの属性を読み、初期値として選択されているインデックスを値に反映する。
   * また、選択状態が DOM にかかれていない時は選択されていないものとして属性をセットする
   * @param {JQuery} $anchors - すべてのアンカー
   * @param {JQuery} $contents - 全てのコンテンツ
   * @param {string} selectedAttr - アンカーの選択状態を反映させる属性
   * @param {string} expandedAttr - コンテンツの開閉状態を反映させる属性
   */
  _syncInitialState ($anchors, $contents, selectedAttr, expandedAttr) {
    const $selectedAnchor = $anchors.filter(`[${selectedAttr}="true"]`)
    const selected = $anchors.index($selectedAnchor)
    $anchors.attr(selectedAttr, 'false')
    $contents.attr(expandedAttr, 'false')

    if (selected >= 0) {
      $anchors.eq(selected).attr(selectedAttr, 'true')
      $contents.eq(selected).attr(expandedAttr, 'true')
      this.data.openedIndex = selected
    }
  }

  /**
   * コンテンツ部分を隠す
   * @param {number} index - 非表示にするコンテンツのインデックス
   * @param {JQuery} $anchors - すべてのアコーディオンのアンカー
   * @param {JQuery} $contents - すべてのコンテンツ
   * @param {string} selectedAttr - アンカーの選択状態を反映させる属性
   * @param {string} expandedAttr - コンテンツの開閉状態を反映させる属性
   */
  _hideContent (index, $anchors, $contents, selectedAttr, expandedAttr) {
    const $anchor = $anchors.eq(index)
    const $content = $contents.eq(index)

    animateAccordion($content, false, {
      before: () => {
        this.trigger('beforeClose', index)
        $anchor.attr(selectedAttr, 'false')

        this.isAnimating = true
        this.animating()
      },
      after: () => {
        $content.attr(expandedAttr, 'false')

        // 不整合を防ぐため、アニメーション後にも状態をセットする
        $anchor.attr(selectedAttr, 'false')

        this.trigger('closed', index)

        this.isAnimating = false
      }
    })
  }

  /**
   * コンテンツ部分を表示する
   * @param {number} index - 表示するコンテンツのインデックス
   * @param {number} prevIndex - 一つ前に表示されていたコンテンツのインデックス
   * @param {JQuery} $anchors - すべてのアコーディオンのアンカー
   * @param {JQuery} $contents - すべてのコンテンツ
   * @param {string} selectedAttr - アンカーの選択状態を反映させる属性
   * @param {string} expandedAttr - コンテンツの開閉状態を反映させる属性
   * @param {boolean} disableScroll - アンカーが画面外に収まるようにスクロールさせるか否か
   */
  _showContent (index, prevIndex, $anchors, $contents, selectedAttr, expandedAttr, disableScroll) {
    const $anchor = $anchors.eq(index)
    const $content = $contents.eq(index)

    animateAccordion($content, true, {
      before: () => {
        this.trigger('beforeOpen', index)
        $content
          .css('z-index', 1) // 新しく表示される方を上にする
          .attr(expandedAttr, 'true')
        $anchor.attr(selectedAttr, 'true')

        this.isAnimating = true
        this.animating()
      },
      after: () => {
        $content.css('z-index', '')
        this.trigger('opened', index)

        this.isAnimating = false
      }
    })
  }

  /**
   * クリックしたアンカーが画面外へアニメーションしてしまう場合は、
   * スクロールさせて画面内へ入るようにする
   * @param {jQuery} $nextAnchor - 次に開くアコーディオンに対応するアンカー
   * @param {jQuery} $nextContent - 次に開くアコーディオンに対応するコンテンツ
   * @param {jQuery} $prevContent - 以前開いていたアコーディオンに対応するコンテンツ
   */
  _adjustScroll ($nextAnchor, $nextContent, $prevContent) {
    this._timeTravel($prevContent, $nextContent, () => {
      if (inViewport($nextAnchor, this.$scrollTarget)) return

      // スクロールする要素から比較した座標を取得する
      let anchorTop = $nextAnchor.offset().top - this.$scrollTarget.offset().top

      if (this.$scrollTarget[0] !== getDefaultScrollTarget()[0]) {
        // ネストされたスクロール要素の時、その要素がスクロールされている座標の値だけ、
        // スクロール先の要素の座標に値を加える必要がある
        anchorTop += this.$scrollTarget.scrollTop()
      }

      // scrollOffset が関数の時、実行して値を取り出す
      let offset = this.scrollOffset
      if (typeof offset === 'function') {
        offset = offset()
      }

      const scrollTop = anchorTop + offset

      // CSS Transition の duration, easing に合わせる
      this.$scrollTarget.animate({
        scrollTop
      }, 500, $.bez([0.44, 0.03, 0.14, 0.98]))
    })
  }

  /**
   * アニメーション後の状態に強制的にした状態で fn を実行する
   * アコーディオンが開いた後に自然にスクロールさせるために用いる
   */
  _timeTravel ($prevContent, $nextContent, fn) {
    $prevContent.css('display', 'none')
    $nextContent.css('display', 'block')

    fn()

    $prevContent.add($nextContent)
      .css('display', '')
  }

  /**
   * アコーディオンを動かした際、サイドナビの固定表示が崩れることがある。その対策。 #823
   */
  animating () {
    $(document).trigger('scroll')
    if (this.isAnimating) {
      window.requestAnimationFrame(() => this.animating())
    }
  }
}

/**
 * AccordionVM の簡易版。特に特別なことはせず、排他的に動くアコーディオンがほしい時はこちらを使う。詳細な説明は {@link AccordionVM}
 *
 * Simplified version of AccordionVM. If you want just an accordion that does not do special thing. Details {@link AccordionVM}
 *
 * @example
 * // wrapper element of accordion
 * const $nav = $('#js-nav')
 *
 * accordion($nav, {
 *   // link or button element that toggles an accordion
 *   anchor: '.js-accordion_anchor',
 *   // content of accordion
 *   content: '.js-accordion_content'
 * })
 */
export function accordion ($wrapper, options) {
  const vm = new AccordionVM($wrapper, options)

  vm.on('select', index => vm.select(index))
  vm.on('deselect', () => vm.select(null))

  return vm
}

/**
 * アコーディオン有効化
 *
 * @param {jQuery} $el
 * @param {Object} options
 * @return {AccordionVM}
 */
export function enableAccordion ($el, options) {
  const forSP = $el.attr('data-accordion-sp') === 'true'
  const disableScroll = $el.attr('data-accordion-disable-scroll') === 'true'

  const finalOptions = assign({
    content: '.js-accordion_content',
    anchor: '.js-accordion_anchor',
    openLabelAttr: 'data-accordion-open-label',
    closeLabelAttr: 'data-accordion-close-label',
    disableScroll
  }, options || {})

  const vm = accordion($el, finalOptions)

  const setLabel = ($anchor, $content, attr) => {
    $anchor
      .add($content)
      .find(`[${attr}]`)
      .addBack(`[${attr}]`)
      .each((i, label) => {
        const $label = $(label)
        const text = $label.attr(attr)
        $label.text(text)
      })
  }

  vm.on('beforeOpen', index => {
    const attr = finalOptions.closeLabelAttr
    const $anchor = $el.find(finalOptions.anchor).eq(index)
    const $content = $el.find(finalOptions.content).eq(index)

    setLabel($anchor, $content, attr)
  })

  vm.on('beforeClose', index => {
    const attr = finalOptions.openLabelAttr
    const $anchor = $el.find(finalOptions.anchor).eq(index)
    const $content = $el.find(finalOptions.content).eq(index)

    setLabel($anchor, $content, attr)
  })

  if (forSP) {
    onChangeLayout({
      pc: () => vm.disable(),
      sp: () => vm.enable()
    })
  }

  return vm
}

/**
 * アコーディオン有効化（全要素）
 *
 * @param {jQuery} $context
 */
export function enableAllAccordion ($context) {
  $('.js-accordion', $context).each((i, el) => {
    const $el = $(el)
    const vm = enableAccordion($el)
    $el.find('.js-accordion_close').on('click', () => {
      vm.select(null)
    })
  })
}
