/* ========================================
 * モジュール
 * ======================================== */

/**
 * 軽量 EventEmitter、継承して使う
 */
export class EventEmitter {
  constructor () {
    this._listeners = {}
  }

  /**
   * イベントリスナを登録する。
   * @param {string} name - event name
   * @param {function(data: *): void} fn - listener function
   */
  on (name, fn) {
    const list = this._listeners[name] = this._listeners[name] || []
    list.push(fn)
  }

  /**
   * on で登録したリスナに対してイベントを飛ばす。
   * @param {string} name - event name
   * @param {*} data - data to emit event listeners
   */
  trigger (name, data) {
    const fns = this._listeners[name] || []
    fns.forEach(fn => fn(data))
  }
}
