import $ from 'jquery'
import { isSP, openAsNewTab, scrollTo } from '@share'
import google from 'google'
import { enableAccordion } from '../../_js/accordion.js'
import { mapStyles } from '../../_js/google_map_styles'

const markers = [
  {
    id: 'tokyo',
    name: 'グロービス経営大学院 東京校',
    lat: '35.6855184',
    lng: '139.7368342',
    icon: '/img/share/ico-map_tokyo.png',
    link: 'https://www.google.co.jp/maps/place/%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E7%B5%8C%E5%96%B6%E5%A4%A7%E5%AD%A6%E9%99%A2+%E6%9D%B1%E4%BA%AC%E6%A0%A1%EF%BC%8F%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E3%83%BB%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%83%BB%E3%82%B9%E3%82%AF%E3%83%BC%E3%83%AB+%E6%9D%B1%E4%BA%AC%E6%A0%A1/@35.6855184,139.7346455,17z/data=!4m5!3m4!1s0x60188c637c7020d5:0xb97218ec878c12ab!8m2!3d35.6855184!4d139.7368342',
    annex: {
      name: 'グロービス・マネジメント・スクール 東京校アネックス',
      lat: '35.684158',
      lng: '139.737088',
      icon: '/img/share/ico-map_tokyo_annex.png'
    }
  },
  {
    id: 'osaka',
    name: 'グロービス経営大学院 大阪校',
    lat: '34.733818',
    lng: '135.499545',
    link: 'https://www.google.co.jp/maps/place/%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E7%B5%8C%E5%96%B6%E5%A4%A7%E5%AD%A6%E9%99%A2+%E5%A4%A7%E9%98%AA%E6%A0%A1%EF%BC%8F%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E3%83%BB%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%83%BB%E3%82%B9%E3%82%AF%E3%83%BC%E3%83%AB+%E5%A4%A7%E9%98%AA%E6%A0%A1/@34.733818,135.4973563,17z/data=!3m1!4b1!4m5!3m4!1s0x6000e439092dd8bd:0x814340dcdb843369!8m2!3d34.733818!4d135.499545'
  },
  {
    id: 'nagoya',
    name: 'グロービス経営大学院 名古屋校',
    lat: '35.1672357',
    lng: '136.8859547',
    link: 'https://www.google.co.jp/maps/place/%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E7%B5%8C%E5%96%B6%E5%A4%A7%E5%AD%A6%E9%99%A2+%E5%90%8D%E5%8F%A4%E5%B1%8B%E6%A0%A1%EF%BC%8F%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E3%83%BB%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%83%BB%E3%82%B9%E3%82%AF%E3%83%BC%E3%83%AB+%E5%90%8D%E5%8F%A4%E5%B1%8B%E6%A0%A1/@35.1672357,136.883766,17z/data=!3m1!4b1!4m5!3m4!1s0x600376deff349b6b:0xd4e5ad775c409801!8m2!3d35.1672357!4d136.8859547'
  },
  {
    id: 'nagoya_new',
    name: 'グロービス経営大学院 名古屋校',
    lat: '35.1720566',
    lng: '136.8827465',
    link: 'https://www.google.co.jp/maps/place/JR%E3%82%B2%E3%83%BC%E3%83%88%E3%82%BF%E3%83%AF%E3%83%BC/@35.1718093,136.8809753,17z/data=!3m1!4b1!4m5!3m4!1s0x600376e7f737b465:0x7c438fde66953d47!8m2!3d35.1718093!4d136.883164'
  },
  {
    id: 'sendai',
    name: 'グロービス経営大学院 仙台校',
    lat: '38.26263',
    lng: '140.881419',
    link: 'https://www.google.co.jp/maps/place/%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E7%B5%8C%E5%96%B6%E5%A4%A7%E5%AD%A6%E9%99%A2+%E4%BB%99%E5%8F%B0%E6%A0%A1/@38.26263,140.8792303,17z/data=!3m1!4b1!4m5!3m4!1s0x5f8a28222eb115ff:0x30979e576cb1221!8m2!3d38.26263!4d140.881419'
  },
  {
    id: 'fukuoka',
    name: 'グロービス経営大学院 福岡校',
    lat: '33.5882051',
    lng: '130.4190246',
    link: 'https://www.google.co.jp/maps/place/%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%93%E3%82%B9%E7%B5%8C%E5%96%B6%E5%A4%A7%E5%AD%A6%E9%99%A2+%E7%A6%8F%E5%B2%A1%E6%A0%A1/@33.5882051,130.4168359,17z/data=!3m1!4b1!4m5!3m4!1s0x3541919189473765:0x6a8a03f49ff2896e!8m2!3d33.5882051!4d130.4190246'
  },
  {
    id: 'yokohama',
    name: 'グロービス経営大学院 横浜・特設キャンパス',
    lat: '35.468369',
    lng: '139.626965',
    link: 'https://www.google.com/maps/place/TKP%E3%82%AC%E3%83%BC%E3%83%87%E3%83%B3%E3%82%B7%E3%83%86%E3%82%A3%E6%A8%AA%E6%B5%9C/@35.4682856,139.6247655,17z/data=!3m1!4b1!4m5!3m4!1s0x60185c1449a74af7:0xbaea77b08044ef3b!8m2!3d35.4682856!4d139.6269595'
  },
  {
    id: 'mito',
    name: 'グロービス経営大学院 横浜・特設キャンパス',
    lat: '36.377361',
    lng: '140.466172',
    link:
    'https://www.google.com/maps/place/3%2BChome-6%2BMinamimachi%2C%2BMito-shi%2C%2BIbaraki-ken%2B310-0021%2C%2BJapan/@36.377361,140.466172,17z/data=!4m5!3m4!1s0x6022244e1b72dfbb:0x60f74aaa67ad26f8!8m2!3d36.377361!4d140.466172'
  }
]

const prefix = 'js-map_'
const icon = '/img/share/ico-map.png'

$(() => {
  $('.js-googlemap').each(function () {
    markers.forEach((marker) => {
      if ($(this).attr('id') === prefix + marker.id) {
        google.maps.event.addDomListener(window, 'load', init(marker))
      }
    })
  })

  const accordions = $('.js-guide_accordion').map((i, el) => {
    const $el = $(el)
    const vm = enableAccordion($(el))

    $el.find('.js-accordion_close').on('click', () => {
      vm.select(null)
    })

    return {
      vm,
      el: $el
    }
  }).get()

  syncHashAndGuide(accordions)
})

function init (marker) {
  const mapOptions = {
    zoom: 17,
    center: new google.maps.LatLng(marker.lat, marker.lng),
    styles: mapStyles
  }
  const mapElement = document.getElementById(prefix + marker.id)
  const map = new google.maps.Map(mapElement, mapOptions)
  const name = marker.name
  const latlng = new google.maps.LatLng(marker.lat, marker.lng)
  const link = marker.link
  let customIcon = icon
  if (marker.icon && marker.id === 'tokyo') {
    customIcon = {
      url: marker.icon,
      scaledSize: isSP() ? new google.maps.Size(175, 59) : new google.maps.Size(351, 118)
    }
  }
  createMarker(name, latlng, customIcon, map, link)

  if (marker.annex) {
    const annexName = marker.annex.name
    const annexLatlng = new google.maps.LatLng(marker.annex.lat, marker.annex.lng)
    const annexLink = marker.annex.link

    let annexCustomIcon = icon
    if (marker.annex.icon) {
      annexCustomIcon = {
        url: marker.annex.icon,
        scaledSize: isSP() ? new google.maps.Size(184, 68) : new google.maps.Size(368, 137)
      }
    }

    createMarker(annexName, annexLatlng, annexCustomIcon, map, annexLink)
  }
}

function createMarker (name, latlng, icon, map, link) {
  const marker = new google.maps.Marker({position: latlng, icon: icon, map: map})
  google.maps.event.addListener(marker, 'click', () => {
    openAsNewTab(link)
  })
}

function syncHashAndGuide (accordions) {
  function open (hash) {
    const target = accordions.filter(a => {
      return a.el.attr('id') === hash
    })[0]

    if (target) {
      target.vm.select(0)
    }
  }

  if (getParam('open')) {
    const openId = getParam('open')
    location.hash = openId
  } else {
    const hash = location.hash.slice(1)
    open(hash)
  }

  $(window).on('hashchange', event => {
    const hash = location.hash.slice(1)
    open(hash)
    scrollTo($(window.location.hash))
  })
}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
function getParam (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
